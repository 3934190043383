<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/user.png" alt="" width="28">
        <h2>角色管理</h2>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="add">新增角色</el-button>
        <el-input clearable v-model="search" placeholder="请输入角色名称" suffix-icon="el-icon-search"
                  @change="skipPage(1)"></el-input>
      </div>
      <el-table
          border :default-sort="{prop: 'date', order: 'descending'}"
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 340px)">
        <el-table-column prop="menuIds" v-if='false'>
        </el-table-column>
        <el-table-column prop="roleId"
                         v-if='false'>
        </el-table-column>
        <el-table-column prop="name" label="角色名称" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="code" label="角色code" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="remark" label="角色描述">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="modifyTime" label="修改时间" sortable show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="90">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)" type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row.roleId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
    <el-dialog  :close-on-click-modal="false"
        :title="action+'角色'"
        class="flex dialogForm "
        top="0" @close="closeDialog"
        :visible.sync="dialogFormVisible"
        width="400px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":" :rules="ruleInline"
               label-width="90px">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="formAction.name" autocomplete="off" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色code" class="formAction" prop="code">
          <el-input placeholder="请输入角色code" v-model="formAction.code" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="角色描述" class="description">
          <el-input type="textarea" placeholder="请输入角色描述" rows="3" v-model="formAction.remark">
          </el-input>
        </el-form-item>
        <el-form-item label="权限选择" class="permission">
          <el-tree :data="permission" show-checkbox node-key="id" :props="defaultProps"
                   ref="tree" :default-checked-keys="stdNode">
          </el-tree>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="addRole('formAction')">确认</el-button>
          <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "role",
  data() {
    return {
      search: "",
      tableData: [],
      page: {
        current: 1,
        size: 10,
         total: 10,
      },
      formAction: {
        name: '',
        code: '',
        remark: '',
        modifyTime: '',
      },
      ruleInline: {
        name: [
          {required: true, message: '角色名称不能为空', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '角色code不能为空', trigger: 'blur'},
        ],
      },
      dialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      checkIds: [],
      permission: [],
      stdNode: [],
      action: '新增',
      isEdit: false,
    }
  },
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.setDefaultChecked()
      }
    }
  },
  mounted() {
    this.getDataFromServer()
    this.getMenusFromServer();
  },
  methods: {
    //获取角色信息
    getDataFromServer() {
      if (this.search) {
        this.page = {
          size: 10,
          current: 1
        }
      }
      this.$get('/oauth/role/page', {
        key: this.search,
        pageSize: this.page.size,
        pageNum: this.page.current,
      }).then(res => {
        this.tableData = res.data.records
        this.page.current = res.data.current;
        this.page.total = res.data.total;
        this.page.size = res.data.size;
      })
    },
    getMenusFromServer() {
      this.$get("/oauth/menu").then(res => {
        this.permission = res.data.children;
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getDataFromServer();
    },
    //   编辑删除
    handleEdit(index, row) {
      this.isEdit = true
      this.action = '编辑'
      this.formAction = {
        name: row.name,
        code: row.code,
        remark: row.remark,
        modifyTime: row.modifyTime,
        roleId: row.roleId,
        type: row.type,
        companyId: row.companyId,
      };
      // this.formAction = this.tableData[index];
      this.dialogFormVisible = !this.dialogFormVisible;
      this.stdNode = row.menuIds.split(",");
    },
    handleDelete(id) {
      this.$confirm('此操作将删除该数据，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("/oauth/role/" + id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getDataFromServer();
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    add() {
      this.isEdit = false
      this.formAction = {}
      this.action = '新增'
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    //弹窗打开后设置默认选中项
    setDefaultChecked() {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.stdNode);
      })
    },
    addRole(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkIds = this.$refs.tree.getCheckedKeys();
          let role = this.formAction;
          role.menuIds = this.checkIds.join(",")
          if (this.isEdit) {
            role.createTime = null;
            role.modifyTime = null;
            this.$put('/oauth/role', role).then(() => {
              this.$message.success("修改成功")
              this.dialogFormVisible = false
              this.getDataFromServer();
            }).catch(err => {
              this.$message.error(err.response.data.message)
            })

          } else {
            this.$post('/oauth/role', role).then(() => {
              this.$message.success("添加成功")
              this.dialogFormVisible = false
              this.getDataFromServer();
            }).catch(err => {
              this.$message.error(err.response.data.message)
            })
          }
        }
      })
    },
    //关闭弹窗的事件
    closeDialog() {
      this.stdNode = [];
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";
</style>
